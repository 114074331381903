import React from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .logo-icon": {
      width: 24,
      height: 24,
      transition: theme.transitions.create(["width", "height"], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, "flex items-center")}>
      <img
        className="logo-icon"
        src="assets/images/logos/logo-r.svg"
        alt="Origin logo"
      />
      <Typography
        className="text-16 ml-12 font-bold logo-text"
        color="textPrimary"
      >
        ORIGIN
      </Typography>
    </div>
  );
}

export default Logo;
