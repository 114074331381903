import DashboardConfig from "./dashboard/";
import InvoicesConfig from "./invoices/";
import FolderConfig from "./folders/";
import ClientsConfig from "./clients";
import ClientsGroupsConfig from "./clients-groups/";
import ActivitiesConfig from "./activities";
import ReportConfig from "./reports";
import activitylogconfig from "./activityLog";
import holidayconfig from "./holidays";
import NewsPageConfig from "./news-block/";
import KPIsPageConfig from "./kpis/";
import ChatAppConfig from "./chat/";
import ProjectInvoiceAppConfig from "./projectInvoice/index";
import SpecimensConfig from "./specimens";
import WorkloadConfig from "./workload";
import AccidentConfig from "./accidents";
import MandatConfig from "./mandats";
import ExpenseConfig from "../admin/expenses";

export const OriginConfigs = [
  DashboardConfig,
  InvoicesConfig,
  FolderConfig,
  ClientsGroupsConfig,
  ClientsConfig,
  ActivitiesConfig,
  ReportConfig,
  activitylogconfig,
  holidayconfig,
  NewsPageConfig,
  KPIsPageConfig,
  ChatAppConfig,
  ProjectInvoiceAppConfig,
  SpecimensConfig,
  WorkloadConfig,
  // AccidentConfig,
  // MandatConfig,
];

export const DynamicMenuConfig = [
  DashboardConfig,
  InvoicesConfig,
  FolderConfig,
  ClientsGroupsConfig,
  ClientsConfig,
  ActivitiesConfig,
  ReportConfig,
  activitylogconfig,
  holidayconfig,
  NewsPageConfig,
  KPIsPageConfig,
  ChatAppConfig,
  SpecimensConfig,
  WorkloadConfig,
  // AccidentConfig,
  // MandatConfig,
  ExpenseConfig,
];
