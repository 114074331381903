import React from "react";
import { Redirect } from "react-router-dom";
import { default as i18next } from "i18next";
import fr_CA from "./i18n/fr_CA";

// mApp avant toute traduction dans le module mandat
i18next.addResourceBundle("fr-CA", "mApp", fr_CA);

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Mandats",
  auth: "login",
  routes: [
    // {
    //   path: "/app/mandats/list",
    //   component: React.lazy(() => import("./views/List")),
    // },
    {
      path: "/app/mandats/item/s1/:numSinistre/:id",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/mandats/item/s2/:numSinistre/:id",
      component: React.lazy(() => import("./views/ItemS2")),
    },
    {
      path: "/app/mandats/item/s3/:numSinistre/:id",
      component: React.lazy(() => import("./views/ItemS3")),
    },
    // {
    //   path: "/app/mandats/item/:id",
    //   component: React.lazy(() => import("./views/MandateDisplay")),
    // },
    // {
    //   path: "/app/mandats/item",
    //   component: () => <Redirect to="/app/mandats/item/new" />,
    // },
    // {
    //   path: "/app/mandats/",
    //   component: () => <Redirect to="/app/mandats/list" />,
    // },
  ],
};

export default config;
