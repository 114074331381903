import { ApolloClient, from, split } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import jwtService from "../originServices/jwtService";
import jwtDecode from "jwt-decode";
import { gql } from "@apollo/client";

// Set Origin Apollo Server link
const uri = process.env.REACT_APP_GRAPHQL_URL;
const wsUri = process.env.REACT_APP_GRAPHQL_WS;

// Set the Apollo Server http link
const httpLink = createUploadLink({ uri });

const token = window.localStorage.getItem("jwt_access_token");
const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      authorization: "Bearer " + token,
    },
  },
});

const middlewares = getMiddlewares();
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  from([...middlewares, httpLink])
);

// Creat the Apollo Client
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: true,
    possibleTypes: {
      AsyncDataNode: ["Activity"],
      ActivityFilterData: [
        "ActivityFilterEmployee",
        "ActivityFilterFolder",
        "ActivityFilterComment",
        "ActivityFilterInsurer",
        "ActivityFilterType",
        "ActivityFilterCategory",
        "ActivityFilterCustomer",
      ],
    },
  }),
});


const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken 
    }
  }
`;

async function refreshToken(client) {
  const refreshToken = window.localStorage.getItem("jwt_access_token");
  if (!refreshToken) {
    throw new Error("Refresh token not available");
  }
  const  { data } = await client.mutate({
    mutation: REFRESH_TOKEN_MUTATION,
    variables: { refreshToken },
  });
  const newAccessToken = data.refreshToken.accessToken; 
 
  window.localStorage.setItem("jwt_access_token", newAccessToken); 

  return newAccessToken;
}

function isTokenExpired(token) {  
  if (!token) return true;
  const { exp } = jwtDecode(token);
  const currentTime = Date.now();
  const expTime = exp * 1000;
  const timeLeft = expTime - currentTime;
 
  return expTime <= currentTime;
}



let isRefreshing = false;
 function getMiddlewares() {
  const authMiddleware = setContext(async (_, props) => {
    let { headers } = props;
    let authHeaders = jwtService.getAuthHeaders();
    if(authHeaders){
      const token = window.localStorage.getItem("jwt_access_token");
    if (isTokenExpired(token) && !isRefreshing) { 
      isRefreshing = true; 
      try { 
        let x = await refreshToken(client); 
        let authHeaderss ={authorization: "Bearer "  ,x} 
        return { headers, ...authHeaderss }; 
      } catch (error) { 
        throw new Error("Unable to refresh token");
      } finally{
        isRefreshing = false;
      }
    }} 
    if (authHeaders) {
      headers = { headers, ...authHeaders };
    }
    return { headers };
  });

  const errorMiddleware = onError(
    ({ response, graphQLErrors, networkError }) => {
      // If the error comes from the graphQL
      if (Array.isArray(graphQLErrors)) {
        const grantError = graphQLErrors.find((error) => {
          return (
            error.extensions.code.toUpperCase() === "GRANT_ERROR"
          );
        });
        if (grantError) {
          jwtService.emit("onAutoLogout", grantError.message);
          jwtService.setSession(null);
          client?.resetStore();
          response.errors = null;
        }
      }
      if (networkError) {
        if (networkError.statusCode === 401) { 
          alert("Votre session a été interrompue en raison d'inactivité. Veuillez vous reconnecter.")
          window.location.reload();
        }
      }
    }
  );

  return [authMiddleware, errorMiddleware];
}

export default client;
