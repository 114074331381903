import React from "react";
import { default as i18next } from "i18next";

import fr_CA from "./i18n/fr_CA";
// import en_CA from "./i18n/fr_CA";

i18next.addResourceBundle("fr-CA", "sApp", fr_CA);
// i18next.addResourceBundle("en-CA", "activities", { locale: en_CA });

const config = {
  settings: {},
  name: "Spécimens",
  auth: "login",
  routes: [
    {
      path: "/app/specimens/item/:id/:isScanned?",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/specimens/item",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/specimens/scan-specimen",
      component: React.lazy(() => import("./views/ScanSpecimen")),
    },
    {
      path: "/app/specimens/due-specimens",
      component: React.lazy(() =>
        import("./views/DueSpecimenLayout")
      ),
    },
    {
      path: "/app/interventions/list",
      component: React.lazy(() =>
        import("./views/ListInterventions")
      ),
    },
    {
      path: "/app/specimens/list",
      component: React.lazy(() => import("./views/List")),
    },
  ],
};

export default config;
