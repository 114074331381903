import React from "react";

const config = {
  settings: {},
  name: "Indicateur charge de travail",
  auth: "workload",
  routes: [
    {
      path: "/app/workload",
      component: React.lazy(() => import("./views/Workload")),
    },
  ],
};

export default config;
