import * as Actions from "../../actions/fuse/shortCuts.actions";
import shortCutsConfig from "app/fuse-configs/shortCutsConfig";

const initialState = shortCutsConfig;

const shortcuts = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SHORTCUTS: {
      return action.payload;
    }
    case Actions.SET_SHORTCUTS: {
      return [...action.payload];
    }

    default: {
      return state;
    }
  }
};

export default shortcuts;
