import React from "react";
import { default as i18next } from "i18next";

import fr_CA from "./i18n/fr_CA";
// import en_CA from "./i18n/fr_CA";

i18next.addResourceBundle("fr-CA", "expenseApp", fr_CA);

const config = {
  settings: {},
  name: "Liste des dépenses",
  auth: "expense_management",
  routes: [
    {
      path: "/app/expenses/item/:id",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/expenses/list",
      component: React.lazy(() => import("./views/List")),
    },
  ],
};

export default config;
