const shortCutsConfig = [];

// if (process.env.NODE_ENV === "development") {
//   shortCutsConfig.push({
//     id: "scan-specimen",
//     title: "Scanner un spécimen",
//     translation: "sApp:scan_specimen",
//     type: "item",
//     icon: "cropfree",
//     url: "/app/specimens/scan-specimen",
//     auth: "specimens",
//   });
// }
export default shortCutsConfig;
