import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withFormsy } from "formsy-react";
import _ from "@lodash";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";

const TextFieldFormsy = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "classes",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "name",
    "margin",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
  ]);

  const errorMessage = props.errorMessage;
  const value = props.value || "";

  const [validationMessage, setValidationMessage] = useState(null);

  function changeValue(event) {
    let value = event.currentTarget.value;
    if (typeof props.valueHandler === "function") {
      value = props.valueHandler(value);
    }
    props.setValue(value);
    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <Tooltip title={props.tooltip || ""}>
      <TextField
        {...importedProps}
        onInvalid={(ev) => {
          ev.preventDefault();
          if (ev.target.validationMessage) {
            setValidationMessage(t(ev.target.validationMessage));
          } else {
            setValidationMessage(null);
          }
        }}
        onInput={(ev) => {
          setValidationMessage(null);
        }}
        onChange={changeValue}
        disabled={props.disabled || false}
        value={value}
        inputRef={ref}
        error={Boolean(errorMessage) || Boolean(validationMessage)}
        helperText={errorMessage || validationMessage}
      />
    </Tooltip>
  );
});

export default React.memo(withFormsy(TextFieldFormsy));
