import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { amber } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { FuseAnimateGroup, FuseUtils } from "@fuse";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { clippingParents } from "@popperjs/core";

const useStyles = makeStyles({
  root: {
    "&.horizontal": {},
    "&.vertical": {
      flexDirection: "column",
    },
  },
  item: {
    textDecoration: "none!important",
    color: "inherit",
  },
  addIcon: {
    color: amber[600],
  },
});

function FuseShortcuts(props) {
  const { t } = useTranslation();
  const shortcutData = useSelector(({ fuse }) => fuse.shortcuts);
  const classes = useStyles(props);

  return (
    <div
      className={clsx(
        classes.root,
        props.variant,
        "flex flex-1",
        props.variant === "vertical" && "grow-0 shrink",
        props.className
      )}
    >
      <FuseAnimateGroup
        enter={{
          animation: "transition.expandIn",
        }}
        className={clsx(
          "flex flex-1",
          props.variant === "vertical" && "flex-col"
        )}
      >
        {shortcutData.map((item) => {
          if (item) {
            const access = FuseUtils.hasPermission({
              slug: item.auth,
              permission: "can_view",
            });
            if (access) {
              return (
                <Link
                  to={item.url}
                  key={item.id}
                  className={classes.item}
                >
                  <Tooltip
                    title={
                      item.translation
                        ? t(`${item.translation}`)
                        : item.title
                    }
                    placement={
                      props.variant === "horizontal"
                        ? "bottom"
                        : "left"
                    }
                  >
                    <IconButton className="w-40 h-40 p-0">
                      {item.icon ? (
                        <Icon>{item.icon}</Icon>
                      ) : (
                        <span className="text-20 font-bold uppercase">
                          {item.title[0]}
                        </span>
                      )}
                    </IconButton>
                  </Tooltip>
                </Link>
              );
            } else {
              return null;
            }
          } else {
            return null;
          }
        })}
      </FuseAnimateGroup>
    </div>
  );
}

FuseShortcuts.propTypes = {};
FuseShortcuts.defaultProps = {
  variant: "horizontal",
};

export default React.memo(FuseShortcuts);
