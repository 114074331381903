import React from "react";
import { Redirect } from "react-router-dom";
import { default as i18next } from "i18next";

import fr_CA from "./i18n/fr_CA";

i18next.addResourceBundle("fr-CA", "aApp", fr_CA); // aApp avant toute traduction dans le module accident

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Sinistres",
  auth: "login",
  routes: [
    {
      path: "/app/accidents/list",
      component: React.lazy(() => import("./views/List")),
    },

    {
      path: "/app/accidents/item/s1/:id",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/accidents/item/s2/:id",
      component: React.lazy(() => import("./views/ItemS2")),
    },
    {
      path: "/app/accidents/item/:id",
      component: React.lazy(() => import("./views/AccidentDetails")),
    },
    {
      path: "/app/accidents/",
      component: () => <Redirect to="/app/accidents/list" />,
    },
  ],
};

export default config;
